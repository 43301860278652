import { template as template_16640886d0ea4a7f9ea5077b2a340a3b } from "@ember/template-compiler";
const FKLabel = template_16640886d0ea4a7f9ea5077b2a340a3b(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
