import { template as template_1c8cd76cd4134ed79ceb02fe1a1373df } from "@ember/template-compiler";
const FKText = template_1c8cd76cd4134ed79ceb02fe1a1373df(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
