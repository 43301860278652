import { template as template_52d98fb97093428f8f3dc6cbd42acc13 } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_52d98fb97093428f8f3dc6cbd42acc13(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
