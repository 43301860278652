import { template as template_744f9aebfb3e47f09104fd1dd05bb0ac } from "@ember/template-compiler";
import Component from "@glimmer/component";
import { modifier } from "ember-modifier";
import highlightSearch from "discourse/lib/highlight-search";
export default class HighlightSearch extends Component {
    highlight = modifier((element)=>{
        highlightSearch(element, this.args.highlight);
    });
    static{
        template_744f9aebfb3e47f09104fd1dd05bb0ac(`
    <span {{this.highlight}}>{{yield}}</span>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
